import React from 'react'

const MonitorTaskAddContainer = Loader.loadModuleComponent('MonitorTasks', 'MonitorTaskAddContainer')

const KeyPersonMonitorTaskAdd = () => {
  return (
    <MonitorTaskAddContainer
      libType={5}
      taskLabel={'隔离人员追踪'}
      taskType="101525"
      alarmThreshold={85}
      taskListModuleName="epidemicPersonTrackTask"
    />
  )
}

export default KeyPersonMonitorTaskAdd
